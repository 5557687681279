import React from "react";
import 'swiper/css';
import "../Style/courseGrid.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import CourseItem from "./CourseItem";
export default function CourseGrid({data,title}){
    return <div className="flex flexCol CourseGridMainDiv">
        <div className="div flex flexCol courseCatTitleDiv">
            <p className="faFont flex alignCenter gap5 textClr3 courseCatTitle">
                {title}  
            </p>
        </div>
        <Swiper className="swiper courseSwiper rel"
            modules={[Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            autoplay={{
                delay: 5000,
            }}
            breakpoints={{
                649:{
                    slidesPerView: 2,
                },
                967:{
                    slidesPerView: 3,
                },
                1024:{
                    slidesPerView : 4
                }
            }}
        >
            {
                data.map((item,index)=>{
                    return <SwiperSlide key={index}>
                        <CourseItem data={item} />
                </SwiperSlide>
                })
            }
        </Swiper>
    </div>
}