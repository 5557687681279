import React from "react";
import { Link } from "react-router-dom";
export default function FilterItem({data}){
    return <div className="flex flexCol rel catsListItem" >
        <Link to={data.url} className={data.name === data.id ? "faFont catItemLink textClr3 flex alignCenter activeCat" : "faFont catItemLink textClr3 flex alignCenter"}>
            <div className="flex checkBoxDiv rel flexCenter">
                <svg className="checkCircle" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"></path></svg>
            </div>
            {
                data.text
            }
        </Link>
    </div>
}