import React, { useEffect, useRef, useState } from "react";
import FullBanner from "./FullBanner";
import { useLocation, useParams } from "react-router-dom";
import "../Style/filterPage.css";
import { scrollToTop } from "./Methods";
import CourseItem from "./CourseItem";
import PagePagintaion from "./PagePagination";
import FilterItem from "./FilterItem";
import NavsList from "./NavsList";
export default function CoursesPage(){
    const [data,setData] = useState({
        resultList : null,
        catsList : null,
        middleBanner : null,
        totalPage : 0,
    });
    const [isLoading,setIsLoading] = useState({
        filter : true,
        data : false
    });
    const location = useLocation();
    const params = useParams();
    const pageNumber = useRef(1);
    const [showFilterList,setShowFilterList] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const [activeCat,setActiveCat] = useState({id:"",fa:"همه دوره ها"})
    useEffect(() => {
        setData({
            ...data,list : null
        });
        setIsLoading({
            ...isLoading,data : true
        });
        fetchData();
    }, [params]);
    function fetchData(){
        pageNumber.current = queryParams.get("p") ? queryParams.get("p") : 1;
        scrollToTop();
        let catsList = [
            {fa:"آموزش گیتار",id:"guitar"},
            {fa:"آموزش پیانو",id:"piano"},
            {fa:"آموزش تئاتر",id:"teater"},
            {fa:"آموزش ویلون",id:"violon"},
        ];
        setTimeout(() => {
            setData({
                middleBanner : {
                    url : "",
                    cover: "/image/middleBanner.webp",
                    mobileCover: "/image/mobileMiddleBanner.webp",
                    title : "something" 
                },
                catsList : catsList,
                resultList : [
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 1450000,
                        cover : "/image/courseImg.webp",
                        discount : 0,
                    },
                    {
                        url : "/course/254",
                        title : "لایو مارکتینگ",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 0,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 1450000,
                        cover : "/image/courseImg.webp",
                        discount : 0,
                    },
                    {
                        url : "/course/254",
                        title : "لایو مارکتینگ",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 0,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 1450000,
                        cover : "/image/courseImg.webp",
                        discount : 0,
                    },
                    {
                        url : "/course/254",
                        title : "لایو مارکتینگ",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 0,
                    },
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                ],
                totalPage : 10,
            });
            catsList.map((item)=>{
                if(item.id === params.query){
                    setActiveCat(item);
                }
            })
            setIsLoading({
                filter : false,
                data : false
            });
        }, 5000);
    }
    function inputKeyUpHandler(e){
        if(e.target.key === "enter"){
            searchData();
        }
    }
    function searchData(){
        setData({
            ...data,list : null
        });
        setIsLoading({
            ...isLoading,data : true
        });
        fetchData();
    }
    
    function changePage(newPageNumber){
        setIsLoading({
            ...isLoading,data : true
        });
        queryParams.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    return <>
        <NavsList data={[{name:"صفحه اصلی",link:"/"},{name:"دوره ها",link:"/videos"}]} />
        <main className="grid mainGrid container" onClick={()=>{showFilterList && setShowFilterList(false)}}>
            {
                isLoading.filter ? <div className="grid courseFilterLoading">
                    <div className="searchInputLoading loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol filterLoadingBtn loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                </div> :
                <div className="grid mainGridRightDiv">
                    <div className="flex flexCol rightSideSearchDiv rel">
                        <input name="q" type="text" className="faFont textSize1 textClr3 courseSearchInput" autoComplete="off" placeholder="جستجوی نام استاد یا دوره" onKeyUp={(e)=>inputKeyUpHandler(e)}/>
                        <button className="flex flexCenter courseSeachSubmitBtn" onClick={()=>searchData}>
                            <svg className="searchIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                        </button>
                    </div>
                    <div className="flex flexCol filterSection">
                        <button className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween" onClick={()=>setShowFilterList(!showFilterList)}>
                            <span className="activeCatText">
                                { activeCat.fa }
                            </span>
                            <svg className={showFilterList ? "activeIcon arrowIcon" : "arrowIcon"} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            <svg className="filterIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                        </button>
                        <div className={showFilterList ? "allCatsList active flex flexCol customScrollbar" : "allCatsList customScrollbar"} >
                            <FilterItem data={{url:"/courses" , name:params.query , text: "همه دوره ها" , id:undefined}} />
                            {
                                data.catsList.map((item,index)=>{
                                    return <FilterItem data={{url:"/courses/"+ item.id , name:params.query , text: item.fa , id:item.id}} key={index}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                data.resultList ? <div className="flex flexCol rel courseSectionLeftDiv">
                    <div className="courseGrid grid">
                        {
                            data.resultList.map((item,index)=>{
                                return <CourseItem data={item} key={index} />
                            })
                        }
                    </div>
                    <PagePagintaion data={{totalNumber:data.totalPage,
                        changePage : changePage, currentPage:pageNumber}} />
                    {
                        isLoading.data && <div className="flex flexCol loadingMoreData container">
                            <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                            </div>
                        </div>
                    }
                </div>
                : <div className="grid resultCourseLoadingGrid">
                    <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                </div>
            }
        </main>
        {data.middleBanner && <FullBanner data={data.middleBanner} />}
    </>
}