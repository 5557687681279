import React, { useEffect, useState } from "react";
import "../Style/login.css";
import { scrollToTop, validateEmail, validPhone } from "./Methods";
import { useNavigate } from "react-router-dom";

export default function Login(){
    const navigate = useNavigate();
    const [checkUserError,setCheckUserError] = useState({result : false,msg : ""});
    const [signError,setSignError] = useState({result : false,msg : ""});
    const [menuType,setMenuType] = useState("");
    const [userPhone,setUserPhone ] = useState("");
    const [loading,setLoading] = useState(true);
    const [signInputValues,setSignInputValues] = useState({n1:"",n2:"",n3:"",n4:"",n5:""})
    useEffect(() => {
        scrollToTop();
        setTimeout(() => {
            setLoading(false);
            setMenuType("checkUser");
        }, 1000);
    }, []);
    function checkUserHandler(e){
        e.preventDefault();
        if(validateEmail(userPhone) || validPhone(userPhone)){
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setMenuType("sign");
                setCheckUserError({result:false,msg:""});
            }, 1000);
        }else{
            setCheckUserError({result:true,msg:"شماره موبایل یا ایمیل صحیح نمی باشد."});
        }
    }
    function signCodeChangeHandler(e){
        if(
            signInputValues.n1 !== "" &&
            signInputValues.n2 !== "" &&
            signInputValues.n3 !== "" &&
            signInputValues.n4 !== "" &&
            signInputValues.n5 !== ""
        ){
            signFormSubmitHandler();
        }else{
            if(e.target.nextElementSibling){
                e.target.nextElementSibling.focus();
            }
        }
    }
    function signFormSubmitHandler(e){
        if(e){
            e.preventDefault();
        }
        setLoading(true);
        setTimeout(() => {
            let rnd = Math.round(Math.random());
            if(rnd){
                setLoading(false);
                setMenuType("enterSignInfo");
                navigate("/");
            }else{
                setLoading(false);
                setSignError({result : true,msg:"کد اشتباه است."})
            }
        }, 1500);
    }
    return <section className="loginMainDiv flex flexCol container">
        {
            menuType === "checkUser" && <form className="flex flexCol checkUserForm" method="post" onSubmit={(event)=>checkUserHandler(event)}>
                <img src="/image/logo.webp" alt="bamamiresi logo" className="loginImg" draggable="false" width={150}/>
                <h1 className="faFont textSize1 textClr3 loginTitle">
                    ورود | ثبت نام
                </h1>
                <p className="faFont textSize9 textClr3 loginText1">
                    لطفا شماره موبایل یا ایمیل خود را وارد کنید.
                </p>
                <input type="text" className={checkUserError.result ? "faFont loginFirstCheckInput error" : "faFont loginFirstCheckInput"} id="userPhone" required autoComplete="phone" name="phone" placeholder="شماره خود را اینجا وارد کنید." value={userPhone} onChange={(event)=>setUserPhone(event.target.value)}/>
                <p className={`faFont textSize9 userCheckHintText ${checkUserError.result ? "show" : ""}`}>
                    {checkUserError.msg}
                </p>
                <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
                    تایید
                </button>
            </form>
        }
        {
            menuType === "sign" && <form method="post" className="flex flexCol signForm" onSubmit={(e)=>signFormSubmitHandler(e)}>
                <button className="flex flexCenter backBtn" onClick={()=>setMenuType("checkUser")} type="button">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
                </button>
                <img src="/image/logo.webp" alt="bamamiresi logo" className="loginImg" draggable="false" width={150}/>
                <h1 className="faFont textSize1 textClr3 loginTitle">
                    کد تایید را وارد کنید.
                </h1>
                <p className="faFont textSize9 textClr3 loginText1">
                    کد تایید برای {userPhone} ارسال شد.
                </p>
                <div className="flex align signCodeInputDiv">
                    <input type="text" maxLength="1" className="signCodeInput faFont textSize9 textClr3 flex flexCenter" required name="signCode1" value={signInputValues.n1} onChange={(e)=>{setSignInputValues({...signInputValues,n1:e.target.value})}} autoComplete="off" onKeyUp={(e)=>signCodeChangeHandler(e)}/>
                    <input type="text" maxLength="1" className="signCodeInput faFont textSize9 textClr3 flex flexCenter" required name="signCode2" value={signInputValues.n2} onChange={(e)=>{setSignInputValues({...signInputValues,n2:e.target.value})}} autoComplete="off" onKeyUp={(e)=>signCodeChangeHandler(e)}/>
                    <input type="text" maxLength="1" className="signCodeInput faFont textSize9 textClr3 flex flexCenter" required name="signCode3" value={signInputValues.n3} onChange={(e)=>{setSignInputValues({...signInputValues,n3:e.target.value})}} autoComplete="off" onKeyUp={(e)=>signCodeChangeHandler(e)}/>
                    <input type="text" maxLength="1" className="signCodeInput faFont textSize9 textClr3 flex flexCenter" required name="signCode4" value={signInputValues.n4} onChange={(e)=>{setSignInputValues({...signInputValues,n4:e.target.value})}} autoComplete="off" onKeyUp={(e)=>signCodeChangeHandler(e)}/>
                    <input type="text" maxLength="1" className="signCodeInput faFont textSize9 textClr3 flex flexCenter" required name="signCode5" value={signInputValues.n5} onChange={(e)=>{setSignInputValues({...signInputValues,n5:e.target.value})}} autoComplete="off" onKeyUp={(e)=>signCodeChangeHandler(e)}/>
                </div>
                <p className={`faFont textSize9 userCheckHintText ${signError.result ? "show" : ""}`}>
                        {signError.msg}
                </p>
                 <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
                    تایید
                </button>
            </form>
        }
        {
            loading && <div className="loginLoading">Loading</div>
        }
    </section>
}