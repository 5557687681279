import React from "react";
import "../Style/homeLoading.css";

export default function HomeLoading(){
    return <>
        <div className="flex flexCol homeLoadingMainDiv">
            <div className="flex flexCol flexCenter mobileLoading">
                <p className="faFont textSize12 textClr3">
                    در حال دریافت اطلاعات
                </p>
                <div className="mobileLoadingItemPerant rel">
                    <div className="mobileLoadingItem"></div>
                    <div className="mobileLoadingItem2"></div>
                </div>
            </div>
            <div className="flex flexCol desktopLoadingMainDiv">
                <div className="flex flexCol sliderLoading rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="flex alignCenter storySection container">
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                </div>
                <div className="flex flexCol container">
                    <div className="flex flexCol middleBannerLoadingItem rel">
                        <div className="loaderItem"></div>
                    </div>
                </div>
            </div>
        </div>
    </>
}