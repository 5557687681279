import React from "react";
import "../Style/navsList.css";
import { Link } from "react-router-dom";
export default function NavsList({data}){
    return <>
        <div className="flex alignCenter navsList container">
            {
                data.map((item,index)=>{
                    return <Link to={item.link} className="faFont textSize1 textClr3 navsListItem rel" key={index}>
                        {
                            item.name
                        }
                    </Link>
                })
            }
        </div>
    </>
}