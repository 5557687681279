import React, { useContext, useEffect, useRef, useState } from "react";
import "../Style/story.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { formatTime, numbersToPer } from "./Methods";
import { AppContext } from '../App';
export default function Story(props){
    const {playerInfo} = useContext(AppContext);
    const [,setPlayer] = playerInfo;

    const [activeStory,setActiveStory] = useState("");
    const [videoElem , setVideoElem] = useState(null);
    const [isPlaying , setIsPlaying] = useState(false);
    const [totalTime,setTotalTime] = useState(null);
    const [currentTime,setCurrentTime] = useState("۰۰:۰۰");
    const [activeStoryIndex,setActiveStoryIndex] = useState(0);
    const [data,setData] = useState(props.data.story);
    const storyProgressDiv = useRef(null);
    const storyTextDiv = useRef(null);
    const storyVideoMainDiv = useRef(null);
    useEffect(() => {
        if(!videoElem){
            setVideoElem(document.querySelector(".storyVideo"));
        }
        storyProgressDiv.current = document.querySelector(".storyProgressDiv");
        storyTextDiv.current = document.querySelector(".storyTextDiv");
        storyVideoMainDiv.current = document.querySelector(".storyVideoMainDiv");

    }, []);
    function showStory(item,index){
        if(videoElem){
            videoElem.src = item.video;
            setActiveStory(item);
            videoElem.play();
            setIsPlaying(true);
            storyTextDiv.current.style.opacity = "0";
            setActiveStoryIndex(index);
            setTotalTime(null);
            setPlayer(null);
        }else{
            setVideoElem(document.querySelector(".storyVideo"));
        }
    }
    function toggleVideoPlay(e){
        if(isPlaying){
            e.target.pause();
            storyTextDiv.current.style.opacity = "1";
        }else{
            e.target.play();
            storyTextDiv.current.style.opacity = "0";
        }
        setIsPlaying(!isPlaying);
    }
    function closeVideo(){
        videoElem.pause();
        setActiveStory(null);
        videoElem.src = "";
        setIsPlaying(false);
        storyTextDiv.current.style.opacity = "1";
        setActiveStoryIndex(0);
        setTotalTime(null);
    }
    function updateTimeHandler(){
        if(!totalTime && videoElem.duration){
            const tempTotal = formatTime(Math.round(videoElem.duration));
            setTotalTime(
                `${tempTotal.minutes} : ${tempTotal.seconds}`
            );
        }
        const time = formatTime(Math.round(videoElem.currentTime));
        setCurrentTime(`${numbersToPer(
          time.minutes.toLocaleString())}:${numbersToPer(time.seconds.toLocaleString())}`);
        const videoDuration = Math.round(videoElem.duration);
        if (videoDuration > 0) {
            const timePassedPercent = Math.round(
                (100 * videoElem.currentTime) / videoDuration
              );
            storyProgressDiv.current.style.width = timePassedPercent + "%";
        }
    }
    
    function endVideoHandler(){
        let tempActiveIndex = activeStoryIndex;
        let tempData = [...data];
        tempData[tempActiveIndex] = {...tempData[tempActiveIndex],played : true};
        setTotalTime("");
        storyTextDiv.current.style.opacity = "1";
        tempActiveIndex++;
        if(tempActiveIndex < data.length){
            showStory(data[tempActiveIndex],tempActiveIndex)
        }else{
            videoElem.src = "";
            setActiveStory(null);
            setIsPlaying(false);
            storyTextDiv.current.style.opacity = "1";
            setActiveStoryIndex(0);
            setTotalTime(null);
        }
        setData(tempData)
    }
    function changeVideoHandler(step){
        let tempActiveIndex = activeStoryIndex;
        tempActiveIndex += step;
        if(tempActiveIndex < 0 || tempActiveIndex >= data.length){
            return
        }
        showStory(data[tempActiveIndex],tempActiveIndex)
    }
    function progressClickHandler(e){
        if (!videoElem.duration) {
            return;
        }
        let posX;
        if (e.type == "touchend") {
            var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
            posX = touch.pageX;
        } else if (e.type === "click") {
            posX = e.clientX;
        }
        let volumePercent = ((posX - storyVideoMainDiv.current.offsetLeft - e.target.offsetLeft) * 100) / e.target.offsetWidth;
        let newTime = (volumePercent * videoElem.duration) / 100;
        videoElem.currentTime = Math.round(newTime);
    }
    return <>
    <div className="flex flexCol container storyContainer">
        {
            <Swiper className="swiper storySwiper rel"
                spaceBetween={15}
                slidesPerView={4}
                breakpoints={{
                    649: {
                        slidesPerView: 6,
                    },
                    868:{
                        slidesPerView: 8,
                    },
                    1150:{
                        slidesPerView: 10,
                    }
                }}
            >
                {
                    data.map((item,index)=>{
                        return <SwiperSlide key={index}>
                        <div className="flex flexCol storyItem rel" onClick={()=>showStory(item,index)}>
                            <div className={item.played ? "flex flexCol storyImgDiv played" : "flex flexCol storyImgDiv"}>
                                <img src={item.cover} alt={item.title} className="storyCoverImg" />
                            </div>
                            <p className="faFont textSize9 textClr3 storyTitle">
                                {
                                    item.title
                                }
                            </p>
                        </div>
                    </SwiperSlide>
                    })
                }
            </Swiper>
        }
    </div>
    <section className={activeStory ? "storyVideoSection flex flexCenter active" : "storyVideoSection"}>
        <div className="flex flexCol storyVideoMainDiv">
            <video src="" className="storyVideo" preload="metadata" onTimeUpdate={()=>updateTimeHandler()} onClick={(event)=>toggleVideoPlay(event)} onEnded={(event)=>endVideoHandler()}></video>
            <button className="closeStoryVideoBtn flex flexCenter" onClick={()=>closeVideo()}>
                <svg stroke="currentColor" fill="#fff" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
            </button>
            <div className="flex flexCol storyTextDiv">
                <p className="faFont textSize9 textClrF storyNameText">
                   متن نمایشی
                </p>
                <a href="" className="faFont textSize9 textClrF storyLink">
                    لینک
                </a>
            </div>
            <div className="flex flexCol totalProgressDiv rel" onClick={(event)=>progressClickHandler(event)}>
                <div className="flex flexCol storyProgressDiv"></div>
            </div>
            <div className="flex alignCenter gap5 timeDiv">
                <p className="faFont textSize8 textClrF totalTime">
                    {
                        totalTime ? totalTime : "۰۰:۰۰"
                    }
                </p>
                <p className="faFont textSize9 textClrF">
                    /
                </p>
                <p className="faFont textSize9 textClrF currentTime">
                    {
                        currentTime
                    }
                </p>
                </div>
            <button className="nextVideoBtn flex flexCenter textSize1" onClick={()=>changeVideoHandler(1)}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>
            </button>
            <button className="preVideoBtn flex flexCenter textSize1" onClick={()=>changeVideoHandler(-1)}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
            </button>
        </div>
    </section>
    </>
}