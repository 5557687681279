import React from "react";
import "../Style/blogItem.css";
import { Link } from "react-router-dom";

export default function BlogItem({data}){
    return <div className="flex flexCol blogItemDiv">
        <Link to={data.url} className="blogItemImgLink" >
            <img src={data.cover} alt={data.title} className="blogItemImg" draggable="false"/>
        </Link>
        <div className="flex flexCol blogInfoDiv">
            <Link to={data.url} className="faFont textSize12 textClr3 blogItemTitle">
                {
                    data.title
                }
            </Link>
            <p className="faFont textClr7 blogItemBody">
                {
                    data.body
                }
            </p>
            <Link className="seeBlogLink faFont flex alignCenter">
                مشاهده مقاله
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
            </Link>
        </div>
    </div>
}